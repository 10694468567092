import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Text from '@magalu/stereo-ui/atoms/Text';
import { themeGet } from '@magalu/stereo-ui-styles';
import Grid from '@magalu/stereo-ui/atoms/Grid';
export var PageContainer = styled(Grid).attrs(function (props) {
  return _extends({
    marginLeft: 3,
    marginRight: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Title = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  font-weight: 500;\n  margin: 24px 0px 16px 0px;\n  line-height: 32px;\n"])), themeGet('fontSizes.5'));
import React from 'react';
import { shape, string } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { PageContainer, Title } from './OrderEligibleList.styles';
var OrderEligibleList = function OrderEligibleList(_ref) {
  var staticProps = _ref["static"];
  var title = staticProps.title;
  return React.createElement(PageContainer, {
    "data-testid": "order-eligible-list"
  }, React.createElement(Title, {
    "data-testid": "order-eligible-list-title"
  }, title));
};
OrderEligibleList.ssr = true;
process.env.NODE_ENV !== "production" ? OrderEligibleList.propTypes = {
  "static": shape({
    title: string
  }).isRequired
} : void 0;
export default withLayoutProps(OrderEligibleList);
import React from 'react';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';
import { OfferTimer as OfferTimerComponent } from '@magalu/stereo-ui/molecules';
import withLayoutProps from '../../../hocs/withLayoutProps';
var OfferTimer = function OfferTimer(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data;
  var height = staticProps.height,
    fontSize = staticProps.fontSize;
  var _ref2 = data.product || {},
    restrictions = _ref2.restrictions;
  return React.createElement(OfferTimerComponent, {
    height: height,
    fontSize: fontSize,
    restrictions: restrictions
  });
};
process.env.NODE_ENV !== "production" ? OfferTimer.propTypes = {
  data: shape({
    product: shape({
      restrictions: shape({
        timer_end_date: string,
        timer_start_date: string
      })
    })
  }),
  "static": shape({
    fontSize: oneOfType([string, number, arrayOf(string), arrayOf(number)]),
    height: oneOfType([string, number, arrayOf(string), arrayOf(number)])
  })
} : void 0;
OfferTimer.defaultProps = {
  data: {
    product: {
      restrictions: {}
    }
  },
  "static": {}
};
OfferTimer.ssr = true;
OfferTimer.displayName = 'OfferTimer';
export default withLayoutProps(OfferTimer);